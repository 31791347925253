<template>
    <div>
        <div class="main-title">诗词列表</div>
        <div class="content-list-page page-content">
            <div class="list-head-box">
                <div data-v-69874ad0="" class="list-head">
                    <p data-v-69874ad0="" class="list-head-title">温馨提示</p>
                    <!-- <ul data-v-69874ad0="">
                        <li data-v-69874ad0="">
                            点评后不能修改，请老师点评前先仔细检查点评内容是否存在错别字
                        </li>
                        <li data-v-69874ad0="">点评完成后系统将自动推送消息到用户微信上</li>
                        <li data-v-69874ad0="">
                            作业点评为优秀时，学生可获得100学币奖励，学币可以用于下载资料以及兑换商品
                        </li>
                        <li data-v-69874ad0="">
                            附件可以上传例文或者修改后的作业图，不能超过10M
                        </li>
                    </ul> -->
                    *****
                </div>
            </div>

            <div style="position: relative; margin-bottom: 10px">
                <div class="margin-l-sm fl-l margin-t-sm" style="margin-top: 8px">
                    <el-input v-model="searchData.title" placeholder="标题"></el-input>
                </div>
                <div class="margin-l-sm fl-l margin-t-sm" style="margin-top: 8px">
                    <el-select v-model="searchData.poem_type_id" clearable placeholder="请选择类型" size="mini">
                        <el-option v-for="item in basicsData.typeList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </div>

                <div class="margin-l-sm fl-l margin-t-sm" style="margin-top: 8px">
                    <el-select v-model="searchData.poem_dynasty_id" clearable placeholder="请选择朝代" size="mini">
                        <el-option v-for="item in basicsData.dynastyList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </div>

                <div class="margin-l-sm fl-l margin-t-sm" style="margin-top: 8px">
                    <el-select v-model="searchData.poem_author_id" clearable placeholder="请选择作者" size="mini">
                        <el-option v-for="item in basicsData.authorList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </div>

                <span class="margin-l-sm fl-l margin-t-sm">
                    <el-button type="primary" icon="el-icon-plus" size="small"
                        @click="$router.push('/listPoem/add')">添加</el-button>
                </span>
                <span class="margin-l-sm fl-l margin-t-sm">
                    <el-button type="primary" icon="el-icon-search" size="small" @click="search">搜索</el-button>
                </span>
            </div>

            <div class="table-content">
                <el-table :header-cell-style="{ padding: 0, 'text-align': 'center' }"
                    :cell-style="{ 'text-align': 'center' }" ref="singleTable" :data="tableData" highlight-current-row
                    v-loading="loading" style="width: 100%; background: #fff">
                    <el-table-column label="ID" property="id" width="80">
                    </el-table-column>
                    <el-table-column label="作者" property="author_name"> </el-table-column>
                    <el-table-column label="类型" property="type_name"> </el-table-column>
                    <el-table-column label="唐朝" property="dynasty_name"> </el-table-column>
                    <el-table-column label="标题" property="title"> </el-table-column>

                    <el-table-column property="created_at" label="创建时间">
                    </el-table-column>
                    <el-table-column property="updated_at" label="更新时间">
                    </el-table-column>

                    <el-table-column property="address" label="操作">
                        <template slot-scope="scope">
                            <span>
                                <span>
                                    <el-link type="primary"
                                        @click="$router.push('/listPoem/details/' + scope.row.id)">详情</el-link>
                                </span>
                            </span>
                            -
                            <span>
                                <span>
                                    <el-link type="primary"
                                        @click="$router.push('/listPoem/edit/' + scope.row.id)">编辑</el-link>
                                </span>
                            </span>
                            -
                            <span>
                                <el-popconfirm icon="el-icon-info" icon-color="red" title="删除后将不可恢复，确定删除吗？"
                                    @confirm="deleted(scope.row.id)">
                                    <el-link slot="reference" type="primary">删除</el-link>
                                </el-popconfirm>
                            </span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div class="table-batch">
                <span class="fl-r">
                    <el-pagination small background :current-page="page" :page-sizes="[10, 20]" :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper" @size-change="setPageSize"
                        @current-change="setPage" :total="total">
                    </el-pagination>
                </span>
                <div style="clear: both"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import _ from "lodash";
export default {
    name: "list",
    data() {
        return {
            searchData: {},
            page: 1,
            pageSize: 10,
            total: 0,
            loading: false,
            tableData: [],
            imageUrl: config.imageUrl,
            basicsData: {},
        };
    },
    methods: {
        ...mapActions("listPoem", ["listPoem", "delPoem"]),
        ...mapActions("common", ["getPoemBaseData"]),
        async deleted(id) {
            const { res_info } = await this.delPoem(id);
            if (res_info != "ok") return;
            this.$message.success("删除成功！");
            this.getList();
        },

        async getList() {
            this.loading = true;
            let form = {
                ...this.searchData,
                page: this.page,
                pageSize: this.pageSize,
            };
            const { data } = await this.listPoem(form);
            this.tableData = data.list;
            this.total = data.total;
            this.loading = false;
        },
        setPageSize(pageSize) {
            this.pageSize = pageSize;
            this.getList();
        },
        setPage(page) {
            this.page = page;
            this.getList();
        },
        search() {
            this.page = 1;
            this.getList();
        },
        async getBasicsData() {
            const { data } = await this.getPoemBaseData()
            this.basicsData = data
        },
    },
    mounted() {
        this.getBasicsData()
        this.getList();
    },
};
</script>
